import React from 'react';
import { bool, string, number, oneOfType } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, MenuItem, Menu } from '@mui/material';
import MoreVertRounded from '@mui/icons-material/MoreVertRounded';

import SiteMenu from './Menu';

const propTypes = { id: oneOfType([string, number]), initialized: bool };

const MobileSiteMenu = ({ id, initialized }) => {
  const navigate = useNavigate();
  const path = `/sites/${id}/setup`;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!id) return null;

  return (
    <>
      {initialized ? (
        <SiteMenu id={id} initialized={initialized} />
      ) : (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'site-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertRounded />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate(path);
              }}
            >
              Setup
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

MobileSiteMenu.propTypes = propTypes;
export default MobileSiteMenu;
