import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoListItem from '../../../../../../components/InfoListItem';
import NameserverInfo from '../../../../components/NameserverInfo';
import SshAccessInfo from '../../../../components/SshAccessInfo';
import IPAddressInfo from '../IPAddressInfo';
import FtpAccessInfo from '../FtpAccessInfo';
import ServerManagement from '../../../../components/ServerManagement';

const ServerInfo = () => {
  const { t } = useTranslation('site');
  const { nameservers } = t('quickConnect.items', { returnObjects: true });

  return (
    <Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack>
            <CardHeader title={t('advancedServer.cardTitle')} />
            <CardContent>
              <Typography variant="body2">
                <Trans
                  i18nKey="site:advancedServer.cardDesc"
                  components={{ Link: <a href="#TODO">TODO</a> }}
                />
              </Typography>
            </CardContent>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ marigTop: 0, paddingTop: 0 }}>
          <CardContent>
            <ServerManagement />
            <Grid container columns={12} display="flex">
              <Grid item xs={12} md={4} flexDirection="column">
                <InfoListItem
                  key={nameservers?.id}
                  title={nameservers?.label}
                  tooltipText={nameservers?.tooltipText}
                >
                  <NameserverInfo />
                </InfoListItem>
              </Grid>
              <Grid item xs={12} md={4} flexDirection="column">
                <SshAccessInfo />
              </Grid>
              <Grid item xs={12} md={4} flexDirection="column">
                <FtpAccessInfo />
              </Grid>
              <Grid item xs={12} md={4} flexDirection="column">
                <IPAddressInfo />
              </Grid>
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default ServerInfo;
