import React from 'react';
// TODO: in the future we will use useParams to get the hostingId from the URL when we allow users to change this within our app
// for now, host app must provide hostingId
// import { useParams } from 'react-router-dom';
import { XyzTransition } from '@animxyz/react';
import { Container, Grid, Typography, Stack } from '@mui/material';
import { useMFEContext } from '../../../../components/MFEProvider';
import OverviewCard from './components/OverviewCard';
import Sites from './components/Sites';
import QuickLinks from './components/QuickLinks';
import UsageStats from './components/UsageStats';
import ServerInfo from './components/ServerInfo';

const propTypes = {};

const HostingDetail = () => {
  const {
    data: { hostingData },
  } = useMFEContext();

  return (
    <XyzTransition xyz="fade left" appear duration="auto" mode="out-in">
      <Container>
        <Stack spacing={3}>
          <Typography variant="h1">
            {hostingData?.productName ?? 'Hosting Plan'}
          </Typography>
          <div>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={8} className="xyz-nested">
                <OverviewCard />
              </Grid>
              <Grid item xs={12} sm={4} className="xyz-nested">
                <QuickLinks />
              </Grid>
              <Grid item xs={12} className="xyz-nested">
                <Sites />
              </Grid>
              <Grid item xs={12} className="xyz-nested">
                <ServerInfo />
              </Grid>
              <Grid item xs={12} className="xyz-nested">
                <UsageStats />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </XyzTransition>
  );
};

HostingDetail.propTypes = propTypes;
export default HostingDetail;
