import { useEffect } from 'react';
import { string, number, bool, shape } from 'prop-types';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const propTypes = {
  dataDogLogsConfig: shape({
    applicationId: string,
    clientToken: string,
    site: string,
    service: string,
    version: string,
    env: string,
    sampleRate: number,
    trackInteractions: bool,
    defaultPrivacyLevel: string,
  }),
  dataDogRumConfig: shape({
    clientToken: string,
    site: string,
    forwardErrorsToLogs: bool,
    sampleRate: number,
    env: string,
  }),
};

const DataDog = ({ dataDogLogsConfig = null, dataDogRumConfig = null }) => {
  useEffect(() => {
    if (dataDogLogsConfig) {
      datadogLogs.init(dataDogLogsConfig);
      // TODO: is there something similar to clean up the datadogLogs session???
      // return () => {} ????
    }
  }, [dataDogLogsConfig]);

  useEffect(() => {
    if (dataDogRumConfig) {
      datadogRum.init(dataDogRumConfig);
      datadogRum.startSessionReplayRecording();
      return () => datadogRum.stopSessionReplayRecording();
    }
  }, [dataDogRumConfig]);

  return null;
};

DataDog.propTypes = propTypes;
export default DataDog;
