import {
  func,
  node,
  oneOfType,
  oneOf,
  bool,
  shape,
  string,
  object,
} from 'prop-types';
import { QueryClientProvider, QueryClient, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools/index.js';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const testingEnv = process.env.NODE_ENV === 'test';

setLogger({
  log: console.log,
  warn: console.warn,
  // ✅ no more errors on the console for tests
  error: testingEnv ? () => {} : console.error,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      ...(testingEnv && { retry: false }),
    },
  },
  // Needed when we upgrade to React Query v4
  // logger: {
  //   log: console.log,
  //   warn: console.warn,
  //   // ✅ no more errors on the console for tests
  //   testingEnv ? () => {} : console.error,
  // },
});

const propsObject = shape({
  className: string,
  style: object,
  onClick: func,
});

const propTypes = {
  children: oneOfType([node, func]),
  disableReactQueryDevTools: bool,
  reactQueryDevToolsConfig: shape({
    initialIsOpen: bool,
    panelProps: propsObject,
    closeButtonProps: propsObject,
    toggleButtonProps: propsObject,
    position: oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
  }),
  apiBaseUrl: string,
  accessToken: string,
  refreshAccessToken: func,
  appEnv: oneOf(['development', 'staging', 'production']),
};

const HttpClient = ({
  children,
  disableReactQueryDevTools = false,
  reactQueryDevToolsConfig,
  apiBaseUrl,
  accessToken,
  refreshAccessToken,
  appEnv,
}) => {
  const getApiBaseUrl = (env, serverUrl) => {
    if (serverUrl) return serverUrl;
    switch (env) {
      case 'production':
        return 'https://hosting.uapi.newfold.com';
      default:
        return 'https://hosting-beta.uapi.newfold.com';
    }
  };

  if (process.env.NODE_ENV !== 'test') {
    axios.defaults.baseURL = getApiBaseUrl(appEnv, apiBaseUrl);
    axios.defaults.headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // This wasn't applying before our axios calls were being made. Using axios defaults for now.
    // const instance = axios.create({
    //   baseURL: getApiBaseUrl(appEnv),
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   }
    // });

    let decodedJWT = jwt_decode(accessToken);

    const shouldRefresh = () => {
      const { iat, exp } = decodedJWT;
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = exp - currentTime;
      const refreshTimeThreshold = (exp - iat) * 0.5;
      return remainingTime <= refreshTimeThreshold;
    };

    axios.interceptors.request.use(
      async (config) => {
        if (shouldRefresh()) {
          const token = await refreshAccessToken();
          const refreshDecodedToken = jwt_decode(token);
          decodedJWT = refreshDecodedToken;

          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
          axios.defaults.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const config = error?.config;
        if (error?.response?.status === 401 && !config._retry) {
          config._retry = true;
          const token = await refreshAccessToken();
          const refreshDecodedToken = jwt_decode(token);
          decodedJWT = refreshDecodedToken;

          axios.defaults.headers = {
            Authorization: `Bearer ${token}`,
          };
          return axios(config);
        }
        return Promise.reject(error);
      },
    );
  }
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!disableReactQueryDevTools && (
        <ReactQueryDevtools {...reactQueryDevToolsConfig} />
      )}
    </QueryClientProvider>
  );
};

HttpClient.propTypes = propTypes;
export default HttpClient;
