import { defaults, createStore, createHook } from 'react-sweet-state';
import { enqueueSnackbar } from 'notistack';

defaults.devtools = true;

const Store = createStore({
  name: `Hosting MFE Alerts`,
  initialState: {
    alertList: [],
  },
  actions: {
    // generateAlert:
    //   (alert) =>
    //   ({ getState, setState }) => {
    //     const newAlert = alert;
    //     newAlert.id = uuid();
    //     newAlert.open = true;
    //     const { alertList } = getState();
    //     setState({ alertList: [...alertList, newAlert] });
    //   },
    generateAlert: (alert) => () => {
      const { description, ...restProps } = alert;
      enqueueSnackbar(description, {
        variant: 'alertTemplate',
        description,
        ...restProps,
      });
    },
    closeAlert:
      (id, reason) =>
      ({ getState, setState }) => {
        if (reason === 'clickaway') {
          return;
        }
        let { alertList } = getState();

        const newState = alertList.map((i) => {
          if (i.id === id) {
            return { ...i, open: false };
          }

          return i;
        });

        setState({ alertList: newState });

        setTimeout(() => {
          alertList = alertList.filter((alert) => alert.id !== id);
          setState({ alertList });
        }, 1000);
      },
    closeAllAlerts:
      () =>
      ({ setState }) => {
        setState({ alertList: [] });
      },
  },
});

export default createHook(Store);
