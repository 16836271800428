import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { oneOf } from 'prop-types';
import { Stack } from '@mui/material';
import {
  Event as EventIcon,
  Update as UpdateIcon,
  UpdateDisabled as UpdateDisabledIcon,
} from '@mui/icons-material';

import { useMFEContext } from '../../../../components/MFEProvider';
import StatusListItem from '../../../../components/StatusListItem';
import {
  getRenewalTooltipStatus,
  getRenewalIconColor,
  getRenewalDateText,
} from '../../../../utils/hosting-renewal-status';

const propTypes = {
  direction: oneOf(['row', 'column']),
};

const RenewalSettings = ({ direction = 'column' }) => {
  const {
    data: { hostingData, renewalCenterLink },
    hostingStatus: {
      renewalStatus: { isExpired, willExpireLater, willExpire },
    },
  } = useMFEContext();
  const { t } = useTranslation('hosting');
  const [renewalIconColor, setRenewalIconColor] = useState('success');
  const [renewalDateText, setRenewalDateText] = useState('');
  const [renewalTooltipText, setRenewalTooltipText] = useState('');
  const [showRenewalCTA, setShowRenewalCTA] = useState(false);
  const expiryDate = hostingData?.expiryDate;
  const hasAutoRenew = hostingData?.hasAutoRenew;

  useEffect(() => {
    const renewalDateText = getRenewalDateText(hasAutoRenew, isExpired);
    const tooltipStatus = getRenewalTooltipStatus(hasAutoRenew, isExpired);
    const iconColor = getRenewalIconColor(
      hasAutoRenew,
      isExpired,
      willExpireLater,
    );
    setShowRenewalCTA(!hasAutoRenew && willExpire);
    setRenewalDateText(t(`renewal.date.${renewalDateText}`));
    setRenewalTooltipText(t(`renewal.tooltip.${tooltipStatus}`));
    setRenewalIconColor(iconColor);
  }, [expiryDate, hasAutoRenew, t, isExpired, willExpire, willExpireLater]);

  const handleExternalLink = () => {
    window.location.assign(renewalCenterLink);
  };

  return (
    (expiryDate || hasAutoRenew) && (
      <Stack direction={{ xs: 'column', md: direction }} alignItems="start">
        {/* Renewal Date */}
        <StatusListItem
          avatarIcon={EventIcon}
          avatarIconColor={renewalIconColor}
          textColor={isExpired ? 'error' : null}
          tooltipText={renewalTooltipText}
          ctaText={t('renewal.date.cta')}
          handleCTA={
            renewalCenterLink && showRenewalCTA ? handleExternalLink : null
          }
        >
          {renewalDateText} {expiryDate}
        </StatusListItem>
        {/* Auto Renew */}
        <StatusListItem
          avatarIcon={hasAutoRenew ? UpdateIcon : UpdateDisabledIcon}
          avatarIconColor={hasAutoRenew ? 'success' : 'error'}
          tooltipText={
            hasAutoRenew
              ? t('renewal.tooltip.isAutoRenewing')
              : t('renewal.tooltip.autoRenewOffWarning')
          }
          ctaText={t('renewal.autoRenew.cta')}
          handleCTA={
            renewalCenterLink && !hasAutoRenew ? handleExternalLink : null
          }
        >
          {t('renewal.autoRenew.msg')}{' '}
          {hasAutoRenew
            ? t('renewal.autoRenew.on')
            : t('renewal.autoRenew.off')}
        </StatusListItem>
      </Stack>
    )
  );
};

RenewalSettings.propTypes = propTypes;
export default RenewalSettings;
