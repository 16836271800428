import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { arrayOf, shape, number, string, bool } from 'prop-types';

import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link,
  Skeleton,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';

import SiteMenu from './Menu';

const propTypes = {
  sites: arrayOf(
    shape({
      available_sites: number,
      max_sites: number,
      limit: number,
      n_pages: number,
      n_rows: number,
      page: number,
      row: arrayOf(
        shape({
          id: number,
          name: string,
          type: string,
          url: string,
          initialized: bool,
          doc_root: string,
          ssl: bool,
        }),
      ),
    }),
  ),
  isLoading: bool,
  isError: bool,
};

const SitesTableBody = ({ sites, isLoading, isError }) => {
  const { t } = useTranslation('sites');

  if (isLoading) {
    return (
      <TableBody data-testid="sites-table-loader">
        {[1].map((key) => (
          <TableRow key={key}>
            <TableCell>
              <Skeleton variant="text" width={100} sx={{ my: 1 }} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={150} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={100} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={30} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (isError) {
    return (
      <TableBody data-testid="sites-table-error">
        <TableRow>
          <TableCell colSpan={6}>{t('sitesTable.tableError')}</TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (sites?.length === 0) {
    return (
      <TableBody data-testid="sites-table-empty">
        <TableRow>
          <TableCell colSpan={6}>{t('sitesTable.noSites')}</TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody data-testid="sites-table">
      {sites?.map(({ id, name, url, pending_url, type, ssl, initialized }) => (
        <TableRow className="xyz-nested" key={`site-${id}`}>
          <TableCell>
            <Typography>
              <Link href={`./sites/${id}`}>
                {ssl ? (
                  <LockIcon
                    sx={{ mr: 1, verticalAlign: 'text-top' }}
                    fontSize="small"
                    color="success"
                    data-testid={`ssl-locked-icon-${id}`}
                  />
                ) : (
                  <LockOpenIcon
                    sx={{ mr: 1, verticalAlign: 'text-top' }}
                    fontSize="small"
                    color="error"
                    data-testid={`ssl-unlocked-icon-${id}`}
                  />
                )}
                {name}
              </Link>
            </Typography>
          </TableCell>
          {!pending_url ? (
            <TableCell>{url}</TableCell>
          ) : (
            <TableCell>
              {pending_url}
              <Tooltip
                title={
                  <Trans
                    i18nKey="sites:pendingUrlTooltip"
                    values={{
                      siteUrl: url,
                    }}
                  />
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <InfoIcon color="neutral" />
                </IconButton>
              </Tooltip>
            </TableCell>
          )}
          {/* a domain must always be present */}
          <TableCell>{type ?? 'WordPress'}</TableCell>
          <TableCell align="right">
            <SiteMenu id={id} initialized={initialized} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

SitesTableBody.propTypes = propTypes;

export default SitesTableBody;
