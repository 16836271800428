import React from 'react';
import { createRoot } from 'react-dom/client';
import resourcesToBackend from 'i18next-resources-to-backend';

import MFEProvider from './components/MFEProvider';
import reportWebVitals from './reportWebVitals';
import App from './App';

/**
 * Initialize the Hosting application.
 *
 * @param {Object} props - Host application configuration object
 *
 * @param {Object} props.data - Host application data parameters
 * @param {string} props.data.accessToken - User JWT to use for HUAPI authentication
 * @param {string} props.data.hostingId - Hosting ID currently selected plan
 * @param {string} props.data.hostingBackref - Hosting Backref currently selected plan
 * @param {string} props.data.apiBaseUrl - Optional base URL for HUAPI including https and root level subpaths
 * @param {string} props.data.purchaseHostingLink - Optional link to purchase another Hosting plan
 * @param {string} props.data.expiryDate - MM/DD/YYYY formatted date of Hosting plan expiry
 *
 * @param {Object} props.callbacks
 * @param {function} props.callbacks.refreshAccessToken - Provide a function to refresh the access token
 *
 * @param {basename} props.basename - Base path to application, such as /hosting/app. May include full URL.
 * @param {string} props.selector;
 */
const init = (props) => {
  // TODO: what if host app props come in the impact the MFEProvider that the MFE did not want?
  // Likely answer: they can validate the host app props here in bootstrap.

  const { selector, basename, ...hostAppProps } = props;

  const hostingId = hostAppProps?.data?.hostingId;
  const hostingBackref = hostAppProps?.data?.hostingBackref || hostingId;

  const el = document.getElementById(selector);
  const root = createRoot(el);

  if (root) {
    root.render(
      <MFEProvider
        {...hostAppProps}
        muiConfig={{ ...hostAppProps.muiConfig, selector }}
        hostingId={hostingBackref}
        basename={basename}
        appEnv={process.env.REACT_APP_ENV}
        i18nConfig={{
          resources: () =>
            resourcesToBackend((language, namespace, callback) => {
              import(`../public/locales/${language}/${namespace}.json`)
                .then((resources) => {
                  callback(null, resources);
                })
                .catch((error) => {
                  callback(error, null);
                });
            }),
        }}
      >
        <App />
      </MFEProvider>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }

  return {
    selector,
    root,
    el,
    // todoSomeHandlerForHostApp: () => {},
  };
};

// If we are in development and in isolation,
// call init immediately
if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
  }

  const devRoot = document.querySelector(`#${process.env.REACT_APP_ROOT_ID}`);
  if (devRoot) {
    // enable mock mode
    if (process.env.REACT_APP_MOCK) {
      const { worker } = require('./mocks/browser');
      worker.start();
    }

    const apiBaseUrl = process.env.REACT_APP_HUAPI_BASE_URL;
    const accessToken = process.env.REACT_APP_HUAPI_JWT;
    const hostingId = process.env.REACT_APP_HOSTING_ID;

    init({
      selector: process.env.REACT_APP_ROOT_ID,
      basename: process.env.PUBLIC_URL,
      callbacks: {
        refreshAccessToken: () => {
          console.error(
            'Your token in `.env` may be expired. Please update it manually and restart the development server.',
          );
        },
      },
      data: {
        apiBaseUrl,
        accessToken,
        hostingId,
        purchaseHostingLink: 'https://www.bluehost.com/',
        renewalCenterLink: 'http://localhost:4200/renewal-center',

        // mock from AMM demo
        hostingData: {
          expiryDate: '08/08/2030',
          accountld: 41589749,
          productCode: 'BH _PKG WP ENT',
          productInstanceld: 'WN.HP.320926252',
          productName: 'WordPress Basic (Hosting)',
          hasAutoRenew: false,
          status: 'Active',
          addHostingAccountUrl: 'https://www.bluehost.com/wordpress/hosting',
        },
      },
    });
  }
}
export { init };
