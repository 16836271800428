import React from 'react';
import { node, string, func, object, oneOfType } from 'prop-types';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

const propTypes = {
  title: string,
  children: node,
  avatarIcon: oneOfType([object, node]),
  avatarIconColor: string,
  textColor: string,
  tooltipText: string,
  ctaText: string,
  handleCTA: func,
  inlineCTA: oneOfType([func, node]),
};

const InfoListItem = ({
  title = null,
  children = null,
  avatarIcon = null,
  avatarIcon: AvatarIcon = null,
  avatarIconColor = null,
  textColor = 'greys.main',
  tooltipText = null,
  ctaText = '',
  handleCTA = null,
  inlineCTA = null,
}) => {
  return (
    <ListItem
      disableGutters
      dense
      alignItems={handleCTA ? 'flex-start' : 'center'}
    >
      {avatarIcon && (
        <ListItemAvatar>
          <Avatar>
            <AvatarIcon {...(avatarIconColor && { color: avatarIconColor })} />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" color={textColor}>
              {title}
            </Typography>
            {tooltipText && (
              <Tooltip title={tooltipText} arrow placement="top-start">
                <IconButton>
                  <InfoIcon color="neutral" />
                </IconButton>
              </Tooltip>
            )}
            {inlineCTA}
          </Stack>
        }
        {...(children && {
          secondary: (
            <Typography variant="body1" component="span">
              {children}
            </Typography>
          ),
        })}
      />
    </ListItem>
  );
};

InfoListItem.propTypes = propTypes;
export default InfoListItem;
