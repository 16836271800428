import React from 'react';
import { bool, string, number, oneOfType } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, MenuItem, Menu } from '@mui/material';
import MoreVertRounded from '@mui/icons-material/MoreVertRounded';

import { useSitesSso } from '@newfold/huapi-js';

const propTypes = { id: oneOfType([string, number]), initialized: bool };

const SiteMenu = ({ id, initialized }) => {
  let navigate = useNavigate();
  let path = `/sites/${id}/setup`;
  const {
    refetch: fetchSiteSsoLink,
    // data: { data: { sso = '' } = {} } = {},
    isFetching,
  } = useSitesSso(
    id,
    {},
    {
      query: { enabled: false },
    },
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!id) return null;

  // Show "Setup" if site is not initialized
  if (initialized === false)
    return (
      <Button variant="contained" onClick={() => navigate(path)}>
        Setup
      </Button>
    );

  // Otherwise show dropdown menu for Site actions
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'site-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertRounded />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={async () => {
            // fetch sso
            const data = await fetchSiteSsoLink();
            window.open(data?.data?.data?.sso, '_blank');
            handleClose();
          }}
        >
          {isFetching ? 'Logging In...' : 'Log In'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            // go to site detail page
            navigate(`./sites/${id}`);
          }}
        >
          Manage
        </MenuItem>
      </Menu>
    </>
  );
};

SiteMenu.propTypes = propTypes;
export default SiteMenu;
