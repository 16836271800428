import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Box,
} from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import { useMFEContext } from '../../../../../../../components/MFEProvider';

const WarningModal = ({ openSiteLimit, setOpenSiteLimit }) => {
  const { t } = useTranslation('sites');

  const {
    data: {
      hostingData: { addHostingAccountUrl },
    },
  } = useMFEContext();

  const handleClose = () => setOpenSiteLimit(false);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openSiteLimit}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="add-site-warning-modal"
    >
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <WarningIcon color="error" sx={{ mr: 2 }} />
          <Typography id="modal-modal-title" variant="h2" component="h2">
            {t('siteLimit')}
          </Typography>
        </Box>

        <Divider />
        <Typography id="modal-modal-description" sx={{ my: 2 }}>
          {t('availableSites')}
        </Typography>

        {/* TODO: check that the link is provided from the host app,
          1. if not, hide this section.
          2. if provided, use link here

            - get this info with useMFEContext */}
        {addHostingAccountUrl && (
          <Link target="_blank" rel="noopener" href={addHostingAccountUrl}>
            <Typography sx={{ mb: 2 }}>{t('purchaseHostingPlan')}</Typography>
          </Link>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleClose}>
            {t('close')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WarningModal;
