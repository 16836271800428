import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
  Stack,
  Skeleton,
  Avatar,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import Tooltip from './CommonTooltip';

const Usage = ({
  cardContentBody,
  isFetching,
  isError,
  cardSubTitle,
  hasIcon,
  value,
  data,
}) => {
  const { t } = useTranslation('settings', { keyPrefix: 'usageStatsCard' });
  return (
    <>
      <CardContent data-testid="stats-info">
        <Stack spacing={2}>
          <Typography variant="body1">{cardContentBody}</Typography>
          {isFetching || isError ? (
            <Stack
              direction="row"
              spacing={1}
              mb={2}
              mt={2}
              data-testid="stats-loader"
            >
              <Skeleton variant="circular" height={24} width={24}>
                <Avatar />
              </Skeleton>
              <Skeleton width="30%" />
            </Stack>
          ) : (
            <div>
              <Grid
                container
                columns={12}
                spacing={{ xs: 4, md: 12 }}
                justifyContent="flex-start"
              >
                <Grid xyz="fade left stagger-2 delay-4" item xs={12} md={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      direction: 'row',
                      alignItems: 'baseline',
                      gap: 1,
                    }}
                  >
                    <Typography mt={2} variant="body2">
                      {cardSubTitle}
                    </Typography>
                    <Tooltip
                      desc={
                        !hasIcon ? t('diskUsageTooltip') : t('warningTooltip')
                      }
                    />
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={value.percentUsed}
                    sx={{ mt: 1, mb: 1 }}
                    color={value.variant}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      direction: 'row',
                      alignItems: 'end',
                      gap: 1,
                    }}
                  >
                    {hasIcon ? (
                      <WarningIcon color="error" data-testid="warning-icon" />
                    ) : (
                      ''
                    )}
                    <Typography variant="body2" data-testid="usage-desc">
                      {`${t('statTxt', {
                        used: data.diskused,
                        available: data.disklimit,
                      })}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
        </Stack>
      </CardContent>
    </>
  );
};
export default Usage;
