import {
  Card,
  CardHeader,
  Box,
  Typography,
  Skeleton,
  Divider,
  CardContent,
  Link,
} from '@mui/material';

import { arrayOf, shape, number, string, bool } from 'prop-types';

import LockIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';

import MobileSiteMenu from './MobileSiteMenu';

const propTypes = {
  sites: arrayOf(
    shape({
      available_sites: number,
      max_sites: number,
      limit: number,
      n_pages: number,
      n_rows: number,
      page: number,
      row: arrayOf(
        shape({
          id: number,
          name: string,
          type: string,
          url: string,
          initialized: bool,
          doc_root: string,
          ssl: bool,
        }),
      ),
    }),
  ),
  isLoading: bool,
};

const cardHeaderSx = {
  background: '#E5ECF0',
  padding: '16px',
};

const SitesCard = ({ sites, isLoading }) => {
  if (isLoading) {
    return [1, 2].map((key) => (
      <Card key={key} sx={{ mb: 4 }} data-testid={`site-card-loader-${key}`}>
        <Box sx={cardHeaderSx}>
          <Box sx={{ display: 'flex', py: 1 }}>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>
                <Skeleton variant="text" width={100} height={16} />
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Skeleton variant="text" width={12} height={16} />
            </Box>
          </Box>
        </Box>
        <CardContent>
          <Typography sx={{ pb: 2 }}>
            <Skeleton variant="text" width={160} height={16} />
          </Typography>
          <Divider />
          <Typography sx={{ py: 2 }}>
            <Skeleton variant="text" width={120} height={16} />
          </Typography>
          <Divider />
        </CardContent>
      </Card>
    ));
  }

  return sites?.map(({ id, ssl, name, url, type, initialized }) => (
    <Card key={id} sx={{ mb: 4 }}>
      <CardHeader
        sx={cardHeaderSx}
        titleTypographyProps={{ variant: 'body1' }}
        action={<MobileSiteMenu id={id} initialized={initialized} />}
        title={
          <Typography>
            <Link href={`./sites/${id}`}>
              {ssl ? (
                <LockIcon
                  sx={{ mr: 1, verticalAlign: 'text-top' }}
                  fontSize="small"
                  color="success"
                  data-testid={`ssl-locked-icon-${id}`}
                />
              ) : (
                <LockOpenIcon
                  sx={{ mr: 1, verticalAlign: 'text-top' }}
                  fontSize="small"
                  color="error"
                  data-testid={`ssl-unlocked-icon-${id}`}
                />
              )}
              {name}
            </Link>
          </Typography>
        }
      />
      <CardContent>
        <Typography sx={{ pb: 2 }}>{name}</Typography>
        <Divider />
        <Typography sx={{ py: 2 }}>{type || 'WordPress'}</Typography>
        <Divider />
      </CardContent>
    </Card>
  ));
};

SitesCard.propTypes = propTypes;

export default SitesCard;
