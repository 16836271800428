import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { bool, string } from 'prop-types';
import { Button, Stack, Tooltip } from '@mui/material';

const propTypes = {
  copyValue: string,
  disable: bool,
};

const Copy = ({ children, copyValue = null, disable = false }) => {
  const { t } = useTranslation('site', { keyPrefix: 'Copy' });
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const findCopy = copyValue || children?.props?.children || children;
    try {
      navigator.clipboard.writeText(findCopy);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);
  return (
    <Stack direction="row" alignItems="baseline">
      {children}
      <Tooltip open={copied} title={t('copiedMsg')} placement="top-end">
        <Button sx={{ p: 0 }} disabled={disable} onClick={handleCopy}>
          {t('copyBtn')}
        </Button>
      </Tooltip>
    </Stack>
  );
};

Copy.propTypes = propTypes;
export default Copy;
