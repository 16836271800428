import set from 'lodash/set.js';
import { createTheme } from '@mui/material/styles';
import LinkBehavior from './LinkBehavior';
import themeOverrides from './themeOverrides';
import { CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';

// get hex color of css variables from the dom/body
const getColor = (cssVariableName) =>
  getComputedStyle(document.body).getPropertyValue(cssVariableName).trim();

const getBackgroundColor = () =>
  window
    .getComputedStyle(document.body, null)
    .getPropertyValue('background-color') === 'rgba(0, 0, 0, 0)'
    ? '#fff'
    : window
        .getComputedStyle(document.body, null)
        .getPropertyValue('background-color');

// generate the pallet object for the theme using the css variables, and the override mappings
const generatePalette = () => {
  const palette = {
    primary: {
      main: '#196bde',
      light: '#5e97e7',
      dark: '#114a9b',
    },
    neutral: {
      main: '#7C7C7C',
      light: '#CCCCCC',
    },
    info: {
      main: '#196BDE',
      light: '#E8F0FB',
      dark: '#114A9B',
    },
    success: {
      main: '#2F964B',
      light: '#EBF9ED',
      dark: '#034A1D',
    },
    secondary: {
      main: '#00476C',
      light: '#E5ECF0',
      dark: '#003856',
    },
    error: {
      main: '#E84A50',
      light: '#FFEBEA',
      dark: '#701116',
    },
    warning: {
      main: '#F2B61B',
      light: '#FAE2AF',
      dark: '#BE5A00',
    },
    text: {
      disabled: '#7C7C7C',
    },
    action: {
      hoverOpacity: 0,
      disabled: grey[400],
    },
    greys: {
      main: '#404040',
      light: grey[100],
    },
  };

  // convert `themeOverrides` obj to array and loop it
  Object.entries(themeOverrides).forEach(
    (
      // destructuring elements we are looping
      [toThemePath, fromCssVar],
    ) => {
      // grab hex color of given css variable from the dom/body
      const overrideColor = getColor(fromCssVar);
      // make sure the color is actually defined
      if (overrideColor) {
        // if so, then add it to our palette
        set(palette, toThemePath, overrideColor);
      }
    },
  );

  return palette;
};

const themeDefault = createTheme({
  palette: generatePalette(),
  typography: {
    fontFamily: ['"Open Sans"', 'Helvetica', 'sans-serif'].join(','),
    fontWeight: 400,
    h1: {
      fontSize: 28,
      fontWeight: 400,
      lineHeight: '36px',
      letterSpacing: -0.8,
    },
    h2: {
      fontSize: 20,
      lineHeight: '27px',
      fontWeight: 600,
      letterSpacing: 0.15,
    },
    h3: {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 600,
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
    },
    h5: {
      fontSize: 12,
    },
    h6: {
      fontSize: 10,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    button: {
      fontSize: 14,
      lineHeight: '15px',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: 0.75,
    },
    caption: {
      fontSize: 11,
      fontWeight: 600,
      lineHeight: '15px',
      letterSpacing: '0.12em',
    },
  },
  components: {
    MuiSkeleton: {
      defaultProps: {
        variant: 'rounded',
        animation: 'wave',
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '1em',
          height: '1em',
          overflow: 'visible',
          backgroundColor: 'transparent',
          marginRight: 10,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <CircularProgress color="neutral" size={20} />,
      },
      // for now, specific variant overrides will be disabled until the Design Team tells us we "need" to override something that isn't available via the "stock" MUI handling.

      // variants: [
      //   {
      //     props: { variant: 'outlined' },
      //     style: {
      //       backgroundColor: 'transparent',
      //       color: themeVars.colors.primaryDark,
      //       border: `1px solid ${themeVars.colors.primaryDark}`,
      //       '&:hover': {
      //         backgroundColor: 'transparent',
      //         color: themeVars.colors.primaryDark,
      //         borderColor: themeVars.colors.primaryDark,
      //       },
      //       '&:active': {
      //         backgroundColor: themeVars.colors.activeLight,
      //         color: themeVars.colors.primaryLight,
      //         borderColor: themeVars.colors.primaryLight,
      //       },
      //     },
      //   },
      //   {
      //     props: { variant: 'contained' },
      //     style: {
      //       color: themeVars.colors.lightFont,
      //       backgroundColor: themeVars.colors.primaryDark,
      //       border: `1px solid ${themeVars.colors.primaryLight}`,
      //       '&:hover': {
      //         backgroundColor: themeVars.colors.primaryLight,
      //         borderColor: themeVars.colors.primaryLight,
      //         boxShadow: themeVars.shadow,
      //       },
      //       '&:active': {
      //         backgroundColor: themeVars.colors.primaryLight,
      //         borderColor: themeVars.colors.primaryLight,
      //         boxShadow: themeVars.shadow,
      //       },
      //     },
      //   },
      //   {
      //     props: { loading: true },
      //     style: {
      //       border: `1px solid ${themeVars.colors.disabledBackground}`,
      //       // need to figure out where the other backgroundColor attr is comingh from
      //       backgroundColor: `${themeVars.colors.disabledBackground}  !important`,
      //       color: themeVars.colors.disabledFont,
      //     },
      //   },

      //   {
      //     props: { disabled: true },
      //     style: {
      //       backgroundColor: themeVars.colors.disabledBackground,
      //       color: themeVars.colors.disabledFont,
      //     },
      //   },
      // ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: ({ theme }) => ({
        color: theme.palette.primary.dark,
      }),
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5, 2),
        }),
      },
      variants: [
        {
          props: { variant: 'text' },
          style: ({ theme }) => ({
            fontWeight: 700,
            padding: 0,
            color: theme.palette.primary.dark,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }),
        },
        {
          props: { variant: 'contained' },
          style: ({ theme }) => ({
            fontWeight: 700,
            background: theme.palette.primary.dark,
            '&:hover': {
              background: theme.palette.primary.main,
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            fontWeight: 700,
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
            '&:hover': {
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
          }),
        },
      ],
      //     variants: [
      //       {
      //         props: { disabled: true },
      //         style: {
      //           backgroundColor: themeVars.colors.disabledBackground,
      //           color: themeVars.colors.disabledFont,
      //         },
      //       },
      //       {
      //         props: { variant: 'outlined' },
      //         style: {
      //           backgroundColor: 'transparent',
      //           color: themeVars.colors.primaryDark,
      //           border: `1px solid ${themeVars.colors.primaryDark}`,
      //           '&:hover': {
      //             backgroundColor: 'transparent',
      //             color: themeVars.colors.primaryDark,
      //             borderColor: themeVars.colors.primaryDark,
      //           },
      //           '&:active': {
      //             backgroundColor: themeVars.colors.activeLight,
      //             color: themeVars.colors.primaryLight,
      //             borderColor: themeVars.colors.primaryLight,
      //           },
      //         },
      //       },
      //       {
      //         props: { variant: 'contained' },
      //         style: {
      //           color: themeVars.colors.lightFont,
      //           backgroundColor: themeVars.colors.primaryDark,
      //           border: `1px solid ${themeVars.colors.primaryLight}`,
      //           '&:hover': {
      //             backgroundColor: themeVars.colors.primaryLight,
      //             borderColor: themeVars.colors.primaryLight,
      //             boxShadow: themeVars.shadow,
      //           },
      //           '&:active': {
      //             backgroundColor: themeVars.colors.primaryLight,
      //             borderColor: themeVars.colors.primaryLight,
      //             boxShadow: themeVars.shadow,
      //           },
      //         },
      //       },
      //     ],
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: theme.palette.primary.dark,
          },
        }),
        track: ({ theme }) => ({
          '.Mui-checked.Mui-checked + &': {
            // Controls checked color for the track
            backgroundColor: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.dark,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            color: theme.palette.primary.dark,
          },
          ':hover': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          '&.Mui-checked': {
            color: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-focused': {
            color: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'inherit',
          textDecoration: 'underline',
          fontSize: 'inherit',
          color: theme.palette.primary.dark,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: 'none',
            color: theme.palette.primary.main,
          },
          '&[disabled]': {
            color: theme.palette.neutral.main,
            pointerEvents: 'none',
          },
        }),
      },
    },
    //   MuiTextField: {
    //     styleOverrides: {
    //       root: {
    //         fontSize: 14,
    //         fontFamily: '"Open Sans"',
    //       },
    //     },
    //   },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // TODO: set h2 defaults to variable and use the variable here, so we aren't duplicating code
          fontSize: 20,
          lineHeight: '27px',
          fontWeight: 600,
          letterSpacing: 0.15,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),
        }),
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        raised: false,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h2',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3, 2, 0),
          ':last-child': {
            paddingBottom: theme.spacing(3),
          },
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        icon: false,
      },
      styleOverrides: {
        root: {
          gap: 12,
          padding: '16px 24px',
          border: '1px solid',
          '& button': {
            color: 'inherit',
          },
          '& .MuiIconButton-root': {
            padding: '4px',
          },
        },
      },
      variants: [
        {
          props: { severity: 'info' },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.info.main}`,
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.dark,
            padding: theme.spacing(2, 3),
            '& button': {
              border: `1px solid ${theme.palette.info.dark}`,
              padding: theme.spacing(2, 3),
            },
            '& .MuiAlert-icon': {
              color: theme.palette.info.dark,
            },
            '& .MuiIconButton-root': {
              border: 'none',
            },
          }),
        },
        {
          props: { severity: 'success' },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.success.main}`,
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.dark,
            padding: theme.spacing(2, 3),
            '& button': {
              border: `1px solid ${theme.palette.success.dark}`,
              padding: theme.spacing(2, 3),
            },
            '& .MuiAlert-icon': {
              color: theme.palette.success.dark,
            },
            '& .MuiIconButton-root': {
              border: 'none',
            },
          }),
        },
        {
          props: { severity: 'error' },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.error.main}`,
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.dark,
            padding: theme.spacing(2, 3),
            '& button': {
              border: `1px solid ${theme.palette.error.dark}`,
              padding: theme.spacing(2, 3),
            },
            '& .MuiAlert-icon': {
              color: theme.palette.error.dark,
            },
            '& .MuiIconButton-root': {
              border: 'none',
            },
          }),
        },
        {
          props: { severity: 'warning' },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.warning.main}`,
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.dark,
            padding: theme.spacing(2, 3),
            '& button': {
              border: `1px solid ${theme.palette.warning.dark}`,
              padding: theme.spacing(2, 3),
            },
            '& .MuiAlert-icon': {
              color: theme.palette.warning.dark,
            },
            '& .MuiIconButton-root': {
              border: 'none',
            },
          }),
        },
      ],
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 600,
        },
      },
      variants: [
        {
          props: { severity: 'info' },
          style: ({ theme }) => ({
            color: theme.palette.info.dark,
          }),
        },
        {
          props: { severity: 'success' },
          style: ({ theme }) => ({
            color: theme.palette.success.dark,
          }),
        },
        {
          props: { severity: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.error.dark,
          }),
        },
        {
          props: { severity: 'warning' },
          style: ({ theme }) => ({
            color: theme.palette.warning.dark,
          }),
        },
      ],
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
          padding: theme.spacing(1, 1.2),
          fontSize: 14,
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey[400],
        }),
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        root: () => ({
          backgroundColor: getBackgroundColor(),
        }),
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { role: 'dialog' },
          style: {
            borderRadius: 8,
          },
        },
      ],
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
        }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: '3em',
          height: '2em',
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.primary.dark}`,
          color: theme.palette.primary.dark,
          borderRadius: 4,
        }),
      },
    },
  },
});

export default themeDefault;
