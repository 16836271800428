import { createContext, useContext } from 'react';
import {
  number,
  bool,
  shape,
  string,
  oneOfType,
  oneOf,
  func,
  node,
} from 'prop-types';
import { getHostingRenewalStatus } from '../../../utils/hosting-renewal-status';
import SkipComponent from './SkipComponent';
import ErrorBoundary from './ErrorBoundary';
import I18N from './I18N';
import DataDog from './DataDog';
import HttpClient from './HttpClient';
import MUI from './MUI';
import ReactSuspense from './ReactSuspense';
import ReactRouterDom from './ReactRouterDom';

const MFEContext = createContext({});
export const useMFEContext = () => useContext(MFEContext);

const propTypes = {
  children: oneOfType([node, func]), // TODO: this should be required
  history: shape({}), // TODO: this should be required
  hostingId: oneOfType([number, string]), // TODO: this should be required
  basename: string, // TODO: this may be required
  hasErrorBoundary: bool,
  errorBoundaryConfig: shape({}),
  hasI18N: bool,
  i18nConfig: shape({}),
  hasDataDog: bool,
  dataDogConfig: shape({}),
  hasReactQuery: bool,
  reactQueryConfig: shape({}),
  hasMUI: bool,
  muiConfig: shape({}),
  hasReactSuspense: bool,
  reactSuspenseConfig: shape({}),
  hasReactRouterDom: bool,
  reactRouterDomConfig: shape({}),
  data: shape({}),
  callbacks: shape({}),
  appEnv: oneOf(['development', 'staging', 'production']),
};

export const MFEProvider = ({
  children,
  hostingId,
  basename,
  history,
  hasErrorBoundary = true,
  errorBoundaryConfig,
  hasI18N = true,
  i18nConfig,
  hasDataDog = true,
  dataDogConfig,
  hasReactQuery = true,
  reactQueryConfig,
  hasMUI = true,
  muiConfig,
  hasReactSuspense = true,
  reactSuspenseConfig,
  hasReactRouterDom = true,
  reactRouterDomConfig,
  data,
  callbacks,
  appEnv,
}) => {
  // here is where the users can control which parts of the MFA architecture they want to use
  const AppErrorBoundary = hasErrorBoundary ? ErrorBoundary : SkipComponent;
  const AppHttpClient = hasReactQuery ? HttpClient : SkipComponent;
  const AppMUI = hasMUI ? MUI : SkipComponent;
  const AppReactSuspense = hasReactSuspense ? ReactSuspense : SkipComponent;
  const AppReactRouterDom = hasReactRouterDom ? ReactRouterDom : SkipComponent;

  // in order to avoid prop-drilling as soon as we receive these props, they get put into the context
  // and they all will be accessible from any component within the MFE application
  const providerData = {
    hostingId,
    basename,
    history,
    hasErrorBoundary,
    errorBoundaryConfig,
    hasI18N,
    i18nConfig,
    hasDataDog,
    dataDogConfig,
    hasReactQuery,
    reactQueryConfig,
    hasMUI,
    muiConfig,
    hasReactSuspense,
    reactSuspenseConfig,
    hasReactRouterDom,
    reactRouterDomConfig,
    data,
    callbacks,
    appEnv,
    hostingStatus: {
      renewalStatus: getHostingRenewalStatus(data?.hostingData?.expiryDate),
      isActive: data?.hostingData?.status.toLowerCase() === 'active',
      isSuspended: data?.hostingData?.status.toLowerCase() === 'suspended',
    },
  };
  return (
    <MFEContext.Provider
      value={providerData}
      style={{ backgroundColor: 'inherit' }}
    >
      <AppMUI {...muiConfig}>
        <AppErrorBoundary {...{ ...errorBoundaryConfig, hasDataDog }}>
          {hasI18N && <I18N {...i18nConfig} />}
          {hasDataDog && <DataDog {...dataDogConfig} />}
          <AppHttpClient
            {...{ ...reactQueryConfig, ...data, ...callbacks, appEnv }}
          >
            <AppReactSuspense {...reactSuspenseConfig}>
              <AppReactRouterDom
                {...{ ...reactRouterDomConfig, basename, history }}
              >
                {children}
              </AppReactRouterDom>
            </AppReactSuspense>
          </AppHttpClient>
        </AppErrorBoundary>
      </AppMUI>
    </MFEContext.Provider>
  );
};

MFEProvider.propTypes = propTypes;
export default MFEProvider;
