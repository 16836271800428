import React from 'react';
import { Stack, Skeleton, Typography } from '@mui/material';
import { useHostingNameservers } from '@newfold/huapi-js';
import Copy from '../../../../components/Copy';
import { useMFEContext } from '../../../../components/MFEProvider';

const NameserverInfo = () => {
  const {
    hostingId,
    hostingStatus: {
      renewalStatus: { isExpired },
      isActive,
    }
  } = useMFEContext();
  
  const { isLoading: isDnsLoading, isError, data: dnsData } =
    useHostingNameservers(hostingId);

  const dnsList = dnsData?.data?.nameservers;

  if (isDnsLoading) {
    return (
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={1}>
          <Skeleton width={185} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Skeleton width={155} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
      </Stack>
    );
  }

  //TODO: ask UX what to output when no nameservers get found
  if (!dnsList || isError) return null;

  return (
    <Stack spacing={0.5}>
      {dnsList?.map((nameserver) => (
        <Copy key={nameserver} disable={isExpired || !isActive}>
          <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
            {nameserver}
          </Typography>
        </Copy>
      ))}
    </Stack>
  );
};

export default NameserverInfo;
