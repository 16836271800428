import { func, object, oneOfType, string } from 'prop-types';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';

const propTypes = {
  error: oneOfType([string, object]),
  resetErrorBoundary: func,
};

const ErrorScene = ({ error = null, resetErrorBoundary = null }) => {
  const formattedError = typeof error === 'string' ? error : error?.message;

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert
        severity="error"
        action={
          resetErrorBoundary && (
            <Button onClick={resetErrorBoundary}>Dismiss</Button>
          )
        }
      >
        <AlertTitle>We are sorry, something went wrong...</AlertTitle>
        {process.env.NODE_ENV === 'development' && formattedError}
      </Alert>
    </Stack>
  );
};

ErrorScene.propTypes = propTypes;
export default ErrorScene;
