import React from 'react';

import { ListItem, ListItemText } from '@mui/material';
import { useMFEContext } from '../../../../components/MFEProvider';

export const HostingInfo = () => {
  const {
    data: { hostingData },
  } = useMFEContext();
  const productName = hostingData?.productName;

  return (
    productName && (
      <ListItem disableGutters dense>
        <ListItemText primary={productName} />
      </ListItem>
    )
  );
};

export default HostingInfo;
