import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Card, CardHeader, CardContent, Typography } from '@mui/material';

import RenewalSettings from '../../../../components/RenewalSettings';
import HostingInfo from '../../../../components/HostingInfo';

const OverviewCard = () => {
  const { t } = useTranslation(['site', 'hosting']);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={t('cardTitle')}></CardHeader>
      <CardContent>
        <Grid
          container
          spacing={2}
          columns={{ xs: 4, sm: 4, md: 12 }}
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t('hosting:infoTitle')}</Typography>
            <HostingInfo />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t('hosting:renewal.title')}</Typography>
            <RenewalSettings />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OverviewCard;
