import React, { useState } from 'react';

import { Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

const CommonTooltip = ({ desc }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          open={open}
          title={desc || ''}
          placement="top-start"
          disableHoverListener
          onClose={handleTooltipClose}
          PopperProps={{
            disablePortal: true,
          }}
        >
          <IconButton onClick={handleTooltipOpen} sx={{ p: 0 }}>
            <InfoRounded color="neutral" />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CommonTooltip;
