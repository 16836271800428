// add overrides here for css variables that should map into our MUI theme
const themeOverrides = {
  'primary.main': '--amm-theme-primary',
  'primary.light': '--amm-theme-primary--light1',
  'primary.dark': '--amm-theme-primary--dark1',

  'secondary.main': '--amm-theme-secondary',
  'secondary.light': '--amm-theme-secondary--light4',
  'secondary.dark': '--amm-theme-secondary--dark1',

  'error.main': '--mdc-theme-error',
  'error.light': '--mdc-theme-error--light',
  'error.dark': '--mdc-theme-error--dark',

  'success.main': '--dc-theme-success',
  'success.light': '--dc-theme-success--light',
  'success.dark': '--dc-theme-success--dark',

  'warning.main': '--dc-theme-warning',
  'warning.light': '--dc-theme-warning--light',
  'warning.dark': '--dc-theme-warning--dark',

  'info.main': '--amm-theme-secondary',
  'info.light': '--amm-theme-secondary--light4',
  'info.dark': '--amm-theme-secondary--dark1',

  'common.black': '--dc-theme-black',
  'common.white': '--dc-theme-white',

  'grey.A100': '--dc-theme-grey--light4',
  'grey.A200': '--dc-theme-grey--light2',
  'grey.A400': '--dc-theme-grey--light1',
  'grey.A700': '--dc-theme-grey',
};

export default themeOverrides;
