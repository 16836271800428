import React from 'react';
import { useTranslation } from 'react-i18next';
import '@animxyz/core';

import { Card, CardHeader, CardContent, Stack } from '@mui/material';

import SsoLink from '../../../../../../components/SsoLink';

const propTypes = {};

const QuickLinks = () => {
  const { t } = useTranslation('quickLinks');
  const buttonList = t('quickLinks.buttonList', { returnObjects: true });

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={t('quickLinks.cardTitle')} />
      <CardContent>
        <Stack spacing={3}>
          {Array.isArray(buttonList) &&
            buttonList.map(({ title, type, queryParams }) => (
              <SsoLink
                key={`sso-link-${title}`}
                fluid
                type={type}
                queryParams={queryParams}
              >
                {title}
              </SsoLink>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

QuickLinks.propTypes = propTypes;
export default QuickLinks;
