import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip, ClickAwayListener } from '@mui/material';

const CopyButton = ({ text, disable }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard.ipAddress.modal',
  });

  const [open, setOpen] = useState(false);

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setOpen(true);
    } catch (err) {
      setOpen(false);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          open={open}
          placement="top-start"
          disableHoverListener
          onClose={handleTooltipClose}
          title={t('copyTooltip')}
          PopperProps={{
            disablePortal: true,
          }}
        >
          <Button
            disabled={disable}
            variant="text"
            sx={{ p: 0, fontWeight: 700 }}
            onClick={copyTextToClipboard}
            data-testid="copy-btn"
          >
            {t('copyBtn')}
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CopyButton;
