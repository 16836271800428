import React from 'react';
import { shape, node, oneOfType, func, string } from 'prop-types';
import { ScopedCssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';

import themeDefault from './themeDefault';
import styleOverrides from './styleOverrides';

const propTypes = {
  children: oneOfType([node, func]),
  theme: shape({}),
  selector: string,
};

const MUI = ({ children, theme = themeDefault, selector = 'hosting-app' }) => (
  <>
    <GlobalStyles
      styles={{
        ...styleOverrides(theme, `#${selector}, .mat-typography`),
      }}
    />
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline sx={{ backgroundColor: 'inherit' }}>
        {children}
      </ScopedCssBaseline>
    </ThemeProvider>
  </>
);

MUI.propTypes = propTypes;
export default MUI;
