import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { orderBy as orderSort } from 'lodash';

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Table,
  TableContainer,
  Grid,
  Stack,
  Box,
} from '@mui/material';

import { useHostingSites } from '@newfold/huapi-js';
import { useMFEContext } from '../../../../../../components/MFEProvider';
import SitesTableHead from './components/SitesTableHead';
import WarningModal from './components/WarningModal';
import SitesTableBody from './components/SitesTableBody';
import SitesCard from './components/SitesCard';

const Sites = () => {
  const { t } = useTranslation('sites');
  const navigate = useNavigate();
  const {
    data: { hostingData },
    hostingId,
  } = useMFEContext();
  const [openSiteLimit, setOpenSiteLimit] = useState(false);

  const { data, isLoading, isError } = useHostingSites(hostingId);

  const sites = data?.data?.rows;
  const availableSites = data?.data?.available_sites;
  const noAvailableSites = !availableSites || availableSites === 0;
  const productName = hostingData?.productName;

  const addSite = () => navigate('sites/add');
  const showNoSitesAvailableModal = () => setOpenSiteLimit(true);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sitesSorted = useMemo(() => {
    return orderSort(sites, [orderBy], [order]);
  }, [order, orderBy, sites]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const tableContainerSx = {
    boxShadow: 0,
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
  };

  const tableSx = {
    border: '1px solid',
    borderColor: 'grey.300',
  };

  const displayDesktop = {
    display: { xs: 'none', md: 'block' },
  };

  const displayMobile = {
    display: { xs: 'block', md: 'none' },
  };

  const tableHeader = (
    <SitesTableHead
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
      rowCount={sites?.length}
    />
  );

  return (
    <>
      <Card>
        <CardHeader
          title={
            productName
              ? t('sitesListTitle', { product: productName })
              : t('sitesListTitlePlaceholder')
          }
        />
        <CardContent>
          <Grid container mb={3}>
            <Grid item xs={12} sm={6} md={6} pb={2}>
              <Typography data-testid="sites-list-desc">
                {sites?.length > 0 ? t('viewSites') : t('noSites')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              display="flex"
              justifyContent={{
                lg: 'flex-end',
                sm: 'flex-end',
              }}
            >
              <Stack
                {...(sites?.length === 0
                  ? { sx: { width: { xs: '100%', md: 'auto' } } }
                  : {})}
              >
                <Button
                  fullWidth={sites?.length === 0}
                  variant="outlined"
                  disabled={isLoading}
                  onClick={
                    noAvailableSites ? showNoSitesAvailableModal : addSite
                  }
                  data-testid="add-site-btn"
                >
                  {t('addSite')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={displayDesktop}>
            <TableContainer sx={tableContainerSx}>
              <Table
                sx={tableSx}
                aria-labelledby="tableTitle"
                data-testid="sites-list"
              >
                {tableHeader}
                <SitesTableBody
                  sites={sitesSorted}
                  getComparator={getComparator}
                  order={order}
                  orderBy={orderBy}
                  isLoading={isLoading}
                  isError={isError}
                />
              </Table>
            </TableContainer>
          </Box>
          <Box sx={displayMobile}>
            <SitesCard sites={sites} isLoading={isLoading} />
          </Box>
        </CardContent>
      </Card>
      <WarningModal
        openSiteLimit={openSiteLimit}
        setOpenSiteLimit={setOpenSiteLimit}
      />
    </>
  );
};

export default Sites;
