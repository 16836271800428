import { useTranslation, Trans } from 'react-i18next';

import {
  Box,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Link,
  Stack,
  Skeleton,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useHostingSites } from '@newfold/huapi-js';
import CopyButton from './CopyButton';
import { useMFEContext } from '../../../../../components/MFEProvider';

const StyledBox = styled(Box)(() => ({
  marginTop: '24px',
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'row',
  '@media (max-width: 320px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
  },
}));

const ViewIPModal = ({ openIPModal, setOpenIPModal, ip }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard.ipAddress.modal',
  });

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenIPModal(false);
  };
  const { hostingId } = useMFEContext();
  const { data, isLoading, isError } = useHostingSites(hostingId);
  const sites = data?.data?.rows;

  return (
    <Dialog
      open={openIPModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
      PaperProps={{
        sx: {
          '@media (max-width: 768px)': {
            position: 'fixed',
            top: 16,
            mt: 0,
            mr: 1,
            mb: 0,
            ml: 1,
          },
        },
      }}
    >
      <DialogTitle fontSize="20px">{t('title')}</DialogTitle>
      <Divider sx={{ mt: 0, mr: 3, mb: 0, ml: 3, borderColor: 'grey.500' }} />
      <DialogContent sx={{ p: 3, color: 'grey.800' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon color="error" sx={{ mr: 2, alignSelf: 'start' }} />
          <Typography>
            <Trans
              i18nKey="settings:nameServerAndIPCard.ipAddress.modal.desc"
              components={{
                Link: !sites?.[0] ? (
                  <Skeleton sx={{ display: 'inline' }} />
                ) : (
                  <Link
                    href={`/sites/${sites?.[0]?.id}/advanced`}
                    sx={{ fontSize: '16px' }}
                  />
                ),
              }}
            />
          </Typography>
        </Box>

        <StyledBox>
          {isLoading || isError ? (
            <Stack direction="row" spacing={1}>
              <Skeleton width={64} height={24} />
              <Skeleton width={185} height={24} />
            </Stack>
          ) : (
            <>
              <Typography sx={{ pr: 1 }}>{t('subTitle')}</Typography>
              <Typography sx={{ fontWeight: 'bold' }}>{ip}</Typography>
              <CopyButton disable={!sites?.[0]} text={ip} />
            </>
          )}
        </StyledBox>
      </DialogContent>
      <DialogActions sx={{ pt: 0, pr: 3, pb: 3, pl: 3 }}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            backgroundColor: blue[900],
            '@media (max-width: 768px)': {
              width: '100%',
            },
          }}
        >
          {t('closeBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewIPModal;
