import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Grid, Typography, Button, Switch, Stack } from '@mui/material';

import { SettingsBackupRestore as SettingsBackupRestoreIcon } from '@mui/icons-material';
import { useHostingAdvancedServer } from '@newfold/huapi-js';
import { useMFEContext } from '../../../../components/MFEProvider';

import useAlerts from '../../../../components/Alerts/alertsStore';

const ServerManagement = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: `serverManagement`,
  });

  const { hostingId } = useMFEContext();
  const [, { generateAlert }] = useAlerts();

  //mocked capabilities until actual service written. TODO-EP4-4015
  const queryKey = 'capabilities';
  const capabilitiesQuery = [`/v1/hosting/${hostingId}/capabilities`];

  const queryFn = () => fetch(capabilitiesQuery).then((res) => res.json());

  const { isSuccess, data } = useQuery(queryKey, queryFn);
  const server_controls = data?.server_controls;
  //mocked capabilities

  const queryClient = useQueryClient();
  const advancedServerStatusKey = ['serverStatusToggle'];

  const {
    isLoading: isAdvancedServerStatusLoading,
    isSuccess: isAdvancedServerStatusSuccess,
    isError: isAdvancedServerStatusError,
  } = useHostingAdvancedServer(hostingId, {
    query: {
      queryKey: advancedServerStatusKey,
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('error'),
          showCloseBtn: true,
        });
      },
    },
  });

  const getAdvancedServerData = queryClient.getQueryData(
    advancedServerStatusKey,
  )?.data;

  const getAdvancedServerStatus = getAdvancedServerData?.status;
  const getAdvancedServerActions = getAdvancedServerData?.actions;

  const handleServerStatusUpdate = async (oldServerStatus) => {
    //TODO part of put back end work in progress.
    return false;
  };
  if (
    (isSuccess && server_controls) ||
    (!isSuccess && isAdvancedServerStatusSuccess)
  ) {
    return (
      <>
        <Grid
          container
          columns={12}
          display="flex"
          justifyContent={'space-between'}
        >
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h3">{t('title')}</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="body2">
                  {getAdvancedServerStatus === 'active' ? 'Running' : 'Stopped'}
                </Typography>
                <Switch
                  disabled={
                    isAdvancedServerStatusError || isAdvancedServerStatusLoading
                  }
                  checked={
                    !isAdvancedServerStatusLoading &&
                    getAdvancedServerStatus === 'active'
                  }
                  onClick={() =>
                    handleServerStatusUpdate(getAdvancedServerStatus)
                  }
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack>
              <Button
                variant="outlined"
                disabled={getAdvancedServerActions?.reboot === 0}
              >
                <SettingsBackupRestoreIcon />
                {t('rebootServerBtn')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default ServerManagement;
