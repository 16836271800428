import React, { useLayoutEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';

import { Alert, AlertTitle, Button, Container, Portal } from '@mui/material';

import Hosting from './scenes/Hosting';
import AlertTemplate from './components/Alerts/AlertTemplate';
import { useMFEContext } from './components/MFEProvider';

const App = () => {
  const {
    data: { hostingData, renewalCenterLink },
    hostingStatus: {
      renewalStatus: { isExpired, willExpireSoon },
      isSuspended,
    },
  } = useMFEContext();
  const { t } = useTranslation('hosting');

  const [showAlertBanner, setShowAlertBanner] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertDescription, setAlertDescription] = useState('');

  const hasAutoRenew = hostingData?.hasAutoRenew;
  const hostingPlan = hostingData?.productName;

  const handleExternalLink = () => {
    window.location.assign(renewalCenterLink);
  };

  useLayoutEffect(() => {
    const hostingStatusCheck = () => {
      if (isSuspended) {
        setAlertTitle(t(`suspended.alert.title`, { hostingPlan }));
        setAlertDescription(t(`suspended.alert.description`));
        setShowAlertBanner(true);
        return;
      }
      if (isExpired && !hasAutoRenew) {
        setAlertTitle(t(`renewal.alerts.isExpired.title`, { hostingPlan }));
        setAlertDescription(t(`renewal.alerts.isExpired.description`));
        setShowAlertBanner(true);
        return;
      }
      if (isExpired && hasAutoRenew) {
        setAlertTitle(
          t(`renewal.alerts.autoRenewFailed.title`, { hostingPlan }),
        );
        setAlertDescription(t(`renewal.alerts.autoRenewFailed.description`));
        setShowAlertBanner(true);
        return;
      }
      if (willExpireSoon && !hasAutoRenew) {
        setAlertTitle(
          t(`renewal.alerts.willExpireSoon.title`, { hostingPlan }),
        );
        setAlertDescription(t(`renewal.alerts.willExpireSoon.description`));
        setShowAlertBanner(true);
        return;
      }
    };
    hostingStatusCheck();
  }, [hasAutoRenew, hostingPlan, isSuspended, t, isExpired, willExpireSoon]);

  return (
    <>
      <Portal>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          Components={{
            alertTemplate: AlertTemplate,
          }}
        />
      </Portal>

      <section
        className="main"
        style={{
          minHeight: '60vh',
          backgroundColor: 'inherit',
          paddingTop: '56px',
          paddingBottom: '56px',
        }}
      >
        {showAlertBanner && (
          <Container
            sx={{
              marginBottom: 3,
            }}
          >
            <Alert
              data-testid="alert-banner"
              severity="error"
              {...(!isSuspended && {
                action: (
                  <Button onClick={handleExternalLink}>
                    {t('renewal.alerts.ctaText')}
                  </Button>
                ),
              })}
            >
              <AlertTitle>{alertTitle}</AlertTitle>
              {alertDescription}
            </Alert>
          </Container>
        )}
        <Routes>
          <Route path="/*" element={<Hosting />} />
        </Routes>
      </section>
    </>
  );
};

export default App;
