import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { bool, string, oneOf, number } from 'prop-types';

import LoadingButton from '@mui/lab/LoadingButton';
import { MenuItem, CircularProgress } from '@mui/material';

import { useSso, useSitesSso } from '@newfold/huapi-js';

import { useMFEContext } from '../MFEProvider';

import useAlerts from '../Alerts/alertsStore';

const SITE = 'site';
const HOSTING = 'hosting';

const propTypes = {
  type: oneOf([SITE, HOSTING]),
  queryParams: string,
  fluid: bool,
  variant: string,
  userId: number,
};

const SsoLink = ({
  children,
  type = null,
  queryParams = '',
  fluid = false,
  variant = 'outlined',
  userId = null,
  ...props
}) => {
  const { t } = useTranslation('site');
  const [, { generateAlert }] = useAlerts();
  const [isLoading, setIsLoading] = useState(false);

  const {
    hostingId,
    hostingStatus: {
      renewalStatus: { isExpired },
      isActive,
    },
  } = useMFEContext();

  const { siteId } = useParams();

  const query = {
    queryKey: queryParams,
    refetchOnWindowFocus: false,
    enabled: false,
  };

  //site sso
  const siteSso = useSitesSso(
    siteId,
    queryParams === ''
      ? {} // for Wordpress sso that uses site sso (default)
      : {
          bounce_name: queryParams,
          ...(userId && { user_id: userId }),
        },
    {
      query,
    },
  );

  //hosting sso
  const hostingSso = useSso(
    hostingId,
    queryParams === ''
      ? {} // for cPanel sso that uses hosting sso (default)
      : {
          app: queryParams,
        },
    {
      query,
    },
  );

  const getSsoByType = (type) => {
    switch (type) {
      case SITE:
        return siteSso;
      case HOSTING:
      default:
        return hostingSso;
    }
  };

  const sso = getSsoByType(type);

  const { refetch, params } = sso;

  const redirectToSsoUrl = async () => {
    // Actually open the window
    var ssoWindow = window.open(`${process.env.PUBLIC_URL}/sso.html`, '_blank');
    setIsLoading(true);

    const { data, error } = await refetch(params);

    if (error) {
      // close the window
      ssoWindow.close();
      setIsLoading(false);
      return generateAlert({
        severity: 'error',
        description: t('error.alertError'),
        showCloseBtn: true,
      });
    }
    const url = data?.data?.url || data?.data?.sso;
    if (url) {
      // set url for window
      ssoWindow.location = url;
      setIsLoading(false);
      return;
    }

    ssoWindow.close();

    // TODO: need some copy from UX
    generateAlert({
      severity: 'error',
      description: t('ssoLink.notFound'),
      showCloseBtn: true,
    });
    setIsLoading(false);
  };

  return variant === 'menuItem' ? (
    <MenuItem
      onClick={redirectToSsoUrl}
      disabled={isExpired || !isActive}
      {...props}
    >
      {isLoading ? <CircularProgress size={20} /> : children}
    </MenuItem>
  ) : (
    <LoadingButton
      loading={isLoading}
      variant={variant}
      color="primary"
      sx={{
        width: fluid ? '100%' : undefined,
        textTransform: 'uppercase',
      }}
      onClick={redirectToSsoUrl}
      disabled={isExpired || !isActive}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

SsoLink.propTypes = propTypes;
export default SsoLink;
