import { func, node, oneOfType } from 'prop-types';

const propTypes = {
  children: oneOfType([node, func]),
};

const SkipComponent = ({ children }) => children;

SkipComponent.propTypes = propTypes;
export default SkipComponent;
