import { func, node, oneOfType, shape, string } from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';

const propTypes = {
  children: oneOfType([node, func]),
  history: shape({}),
  basename: string,
};

const ReactRouterDom = ({ children, history, basename }) => (
  <Router history={history} basename={basename}>
    {children}
  </Router>
);

ReactRouterDom.propTypes = propTypes;
export default ReactRouterDom;
