import { Link } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoListItem from '../../../../../../components/InfoListItem';
import ViewIPModal from '../../../../../Site/scenes/Settings/components/ViewIPModal';
import { useHostingInfo } from '@newfold/huapi-js';
import { useMFEContext } from '../../../../../../components/MFEProvider';

const IPAddressInfo = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard',
  });
  const [openIPModal, setOpenIPModal] = useState(false);
  const showIPModal = () => setOpenIPModal(true);

  const { hostingId } = useMFEContext();
  const { data } = useHostingInfo(hostingId);
  return (
    <>
      <InfoListItem
        title={t('ipAddress.title')}
        tooltipText={t('ipAddress.tooltip')}
      >
        <Link component="button" onClick={showIPModal}>
          {t('ipAddress.viewBtn')}
        </Link>
      </InfoListItem>
      <ViewIPModal
        openIPModal={openIPModal}
        setOpenIPModal={setOpenIPModal}
        ip={data?.data?.ip}
      />
    </>
  );
};

export default IPAddressInfo;
