import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  CardHeader,
  CardContent,
  Typography,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHostingInfoDiskUsage } from '@newfold/huapi-js';
import { useMFEContext } from '../../../../../../components/MFEProvider';
import Usage from '../../../../../../utils/Usage';

const UsageStats = () => {
  const { hostingId } = useMFEContext();
  const { t } = useTranslation('settings', { keyPrefix: 'usageStatsCard' });
  const [value, setValue] = useState({ percentUsed: 0, variant: '' });
  const [hasIcon, setHasIcon] = useState(false);

  const { data, isFetching, isError } = useHostingInfoDiskUsage(hostingId, {
    query: {
      select: ({ data }) => data,
      onSuccess: (data) => {
        if (data.disklimit !== 'Unlimited') {
          const percentUsed =
            Number(data.diskused.slice(0, -2) / data.disklimit.slice(0, -2)) *
            100;
          let variant = '';
          switch (true) {
            case percentUsed >= 90:
              variant = 'error';
              setHasIcon(true);
              break;
            case percentUsed < 90 && percentUsed >= 70:
              variant = 'warning';
              break;
            default:
              variant = 'success';
          }
          return setValue({ percentUsed, variant });
        }
        return setValue({ percentUsed: 0, variant: 'primary' });
      },
    },
  });

  return (
    <Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack>
            <CardHeader title={t('usageCardTitle')} />
            <CardContent>
              <Typography variant="body2">{t('usageCardDesc')}</Typography>
            </CardContent>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ marigTop: 0, paddingTop: 0 }}>
          <Usage
            isFetching={isFetching}
            isError={isError}
            cardSubTitle={t('subTitle')}
            hasIcon={hasIcon}
            value={value}
            data={data}
          />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default UsageStats;
