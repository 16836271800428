import { bool, func, shape, node, oneOfType } from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import datadogErrorHandler from '../DataDog/errorHandler';
import DefaultErrorScene from './ErrorScene';

const propTypes = {
  children: oneOfType([node, func]),
  ErrorScene: oneOfType([node, func]),
  hasDataDog: bool,
  onError: func,
  history: shape({}),
};

const ErrorBoundary = ({
  ErrorScene = DefaultErrorScene,
  onError = null,
  children = null,
  hasDataDog = true,
  history,
}) => {
  const errorhandler = (error, errorInfo) => {
    if (onError) {
      return onError(error, errorInfo);
    }

    if (hasDataDog) {
      return datadogErrorHandler(error, errorInfo, history);
    } // TODO: figure out what we want to do in case users opt-out of DataDog, and no onError has been provided inside errorBoundaryConfig
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorScene} onError={errorhandler}>
      {children}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = propTypes;
export default ErrorBoundary;
