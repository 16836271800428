import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HostingDetail from './scenes/HostingDetail';
// import HostingList from './scenes/HostingList';

const Sites = React.lazy(() => import('../Sites'));

const Hosting = () => (
  <Routes>
    <Route path="/">
      <Route index element={<HostingDetail />} />
      <Route path="sites/*" element={<Sites />} />
    </Route>
  </Routes>
);

export default Hosting;
