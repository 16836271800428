import React, { forwardRef } from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';
import { closeSnackbar } from 'notistack';

const AlertTemplate = forwardRef((props, ref) => {
  const alertProps = props;

  const alertFunc = () => {
    alertProps?.cta();
    closeSnackbar(alertProps?.id);
  };

  return (
    <Alert
      ref={ref}
      {...alertProps}
      severity={alertProps?.severity || 'error'}
      {...(alertProps?.showCloseBtn && {
        onClose: () => closeSnackbar(alertProps?.id),
      })}
      {...(alertProps?.ctaText && {
        action: (
          <Button onClick={alertFunc} color={alertProps?.severity}>
            {alertProps?.ctaText}
          </Button>
        ),
      })}
    >
      {alertProps?.title && <AlertTitle>{alertProps?.title}</AlertTitle>}
      {alertProps?.description}
    </Alert>
  );
});
export default AlertTemplate;
