import { Suspense } from 'react';
import { func, node, oneOfType } from 'prop-types';

import DefaultLoadingScene from './LoadingScene';

const propTypes = {
  children: oneOfType([node, func]),
  LoadingScene: oneOfType([node, func]),
};

// TODO: the fallback component renders and goes away so fast it looks more like a glitch than a loading state
const ReactSuspense = ({ children, LoadingScene = DefaultLoadingScene }) => {
  // convert to an instance to match fallback propType
  const loadingScene = <LoadingScene />;
  return <Suspense fallback={loadingScene}>{children}</Suspense>;
};

ReactSuspense.propTypes = propTypes;
export default ReactSuspense;
