import React from 'react';
import { oneOfType, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  href: oneOfType([
    shape({
      hash: string,
      pathname: string,
      search: string,
    }),
    string,
  ]).isRequired,
};

// https://mui.com/material-ui/guides/routing/#global-theme-link
const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, children, target, ...other } = props;
  // Enable links with an href prop to use the React Router `to` prop
  // Map href (MUI) -> to (react-router)
  // Need to allow for 'external' links - we'll look for 'http' and if truthy, return a TRUE href; otherwise will return `to`
  return target === '_blank' ? (
    <a href={href} target="_blank" rel="noopener noreferrer" {...other}>
      {children}
    </a>
  ) : (
    <Link ref={ref} to={href} {...other}>
      {children}
    </Link>
  );
});

LinkBehavior.propTypes = propTypes;
export default LinkBehavior;
