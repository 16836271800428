import * as React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { func, string } from 'prop-types';

const propTypes = {
  order: string,
  orderBy: string,
  onRequestSort: func,
};

const headCells = [
  {
    id: 'name',
    label: 'Site',
  },
  {
    id: 'url',
    label: 'Domain Name',
  },
  {
    id: 'type',
    label: 'Platform',
  },
  {
    id: 'login',
    label: '',
  },
];

const SitesTableHead = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        backgroundColor: '#E5ECF0',
        py: 2,
        px: 3,
        fontWeight: '500',
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.id === orderBy}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              data-testid={`${headCell.id}-sort-btn`}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SitesTableHead.propTypes = propTypes;
export default SitesTableHead;
