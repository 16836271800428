import moment from 'moment';

const getHostingRenewalStatus = (expiryDate) => {
  const today = moment().startOf('day');
  const expDate = moment(expiryDate, 'MM/DD/YYYY', true);
  const ttl = moment.duration(expDate.diff(today)).asDays();

  const status = {
    isExpired: ttl <= 0,
    willExpireSoon: ttl > 0 && ttl <= 14,
    willExpire: ttl <= 45,
    willExpireLater: ttl > 45,
  };

  return status;
};

const getRenewalDateText = (hasAutoRenew, isExpired) => {
  if (hasAutoRenew && !isExpired) return 'isRenewing';
  if (!hasAutoRenew && !isExpired) return 'isExpiring';
  return 'isExpired';
};

const getRenewalTooltipStatus = (hasAutoRenew, isExpired) => {
  if (hasAutoRenew && !isExpired) return 'isAutoRenewing';
  if (!hasAutoRenew && !isExpired) return 'renewDateWarning';
  if (isExpired) return 'renewDateExpired';
};

const getRenewalIconColor = (hasAutoRenew, isExpired, willExpireLater) => {
  if ((hasAutoRenew && !isExpired) || (!hasAutoRenew && willExpireLater)) {
    return 'success';
  }
  return 'error';
};

export {
  getHostingRenewalStatus,
  getRenewalTooltipStatus,
  getRenewalIconColor,
  getRenewalDateText,
};
