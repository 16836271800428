import React from 'react';
import { node, string, func, object, oneOfType, bool } from 'prop-types';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Stack,
  Tooltip,
  Typography,
  Link,
  IconButton,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

const propTypes = {
  children: node.isRequired,
  avatarIcon: oneOfType([object, node, func]),
  avatarIconColor: string,
  textColor: string,
  tooltipText: oneOfType([string, object]),
  ctaText: string,
  handleCTA: func,
  inlineCTA: node,
  disableCTA: bool,
  ctaIsLoading: bool,
  isLoading: bool,
  isError: bool,
  dataTestId: string,
};

const StatusListItem = ({
  children,
  avatarIcon = null,
  avatarIconColor = null,
  textColor = 'neutral',
  tooltipText = null,
  ctaText = '',
  handleCTA = null,
  inlineCTA = null,
  disableCTA = false,
  ctaIsLoading = false,
  isLoading = false,
  isError = false,
  dataTestId = 'status',
  avatarIcon: AvatarIcon = null,
}) =>
  isLoading ? (
    <Stack direction="row" spacing={1} mb={2}>
      <Skeleton variant="circular" height={24} width={24}>
        <Avatar />
      </Skeleton>
      <Skeleton height={36} width="100%" />
    </Stack>
  ) : (
    !isError && (
      <ListItem
        disableGutters
        dense
        alignItems={handleCTA ? 'flex-start' : 'center'}
      >
        {avatarIcon && (
          <ListItemAvatar>
            <Avatar>
              <AvatarIcon
                {...(avatarIconColor && { color: avatarIconColor })}
                data-testid="avatar-icon"
              />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          data-testid={dataTestId}
          primary={
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" color={textColor}>
                {children}
              </Typography>
              {tooltipText && (
                <Tooltip
                  title={tooltipText}
                  arrow
                  placement="top"
                  data-testid="tooltip"
                >
                  <IconButton>
                    <InfoIcon color="neutral" />
                  </IconButton>
                </Tooltip>
              )}
              {inlineCTA}
            </Stack>
          }
          {...(handleCTA && {
            secondary: ctaIsLoading ? (
              <CircularProgress size={16} />
            ) : (
              <Link
                component="button"
                onClick={handleCTA}
                disabled={disableCTA}
                data-testid={`${dataTestId}-secondary-link`}
              >
                {ctaText}
              </Link>
            ),
          })}
        />
      </ListItem>
    )
  );

StatusListItem.propTypes = propTypes;
export default StatusListItem;
