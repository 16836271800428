import { func, object, oneOfType } from 'prop-types';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const propTypes = {
  resources: oneOfType([object, func]),
};

const I18N = ({ resources = null }) => {
  const init = {
    // don't pass resources directly if a function is provided
    resources: typeof resources === 'function' ? undefined : resources,
    debug: Boolean(process.env.REACT_APP_I18N_DEBUGGING),
    returnObjects: true,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  };
  if (typeof resources === 'function') {
    i18n
      .use(resources())
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(init);
  } else {
    i18n.use(LanguageDetector).use(initReactI18next).init(init);
  }
  return null;
};

I18N.propTypes = propTypes;
export default I18N;
