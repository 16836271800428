import { datadogLogs } from '@datadog/browser-logs';
import StackTrace from 'stacktrace-js';

const datadogErrorHandler = (error, errorInfo, history) => {
  // @TODO: use history arg
  let stackSource;
  let stackSourceError;

  // TODO: window.location.hash?? we are not using history-hash any more
  const defaultRouteName = window.location.hash === '' ? 'Unknown' : window.location.hash;
  let routeName = window.location.hash.substring(1)?.split('/')?.[1]?.toUpperCase();
  routeName = !routeName || routeName === '' ? defaultRouteName : routeName;

  const loggerEventName = `${routeName} | Error Boundary`;
  const loggerBaseObject = {
    host: window.location.origin,
    service: window.location.href,
    error_message: error?.toString(),
    error_component_stack: errorInfo?.componentStack?.toString(),
  };

  try {
    StackTrace.fromError(error)
      .then(errorFrame => {
        stackSource = errorFrame[0]?.source || 'Failed to fetch stack trace source';
      })
      .catch(stackError => {
        stackSourceError = stackError;
      })
      .finally(() => {
        datadogLogs.logger.error(loggerEventName, {
          ...loggerBaseObject,
          ...(stackSource && { error_stack_source: stackSource }),
          ...(stackSourceError && { stack_source_error: stackSourceError }),
        });
      });
  } catch (err) {
    datadogLogs.logger.error(loggerEventName, {
      ...loggerBaseObject,
      ...(stackSourceError && { stack_source_error: stackSourceError }),
    });
  }
}

export default datadogErrorHandler;
