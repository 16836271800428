import { useTranslation } from 'react-i18next';
import { Skeleton, Stack, Button, Typography } from '@mui/material';
import { useHostingSites, useHostingSshInfo } from '@newfold/huapi-js';
import { useMFEContext } from '../../../../components/MFEProvider';
import InfoListItem from '../../../../components/InfoListItem';
import Copy from '../../../../components/Copy';

const SshAccessInfo = () => {
  const { t } = useTranslation('site');
  const { ssh } = t('quickConnect.items', { returnObjects: true });

  const {
    data: { hostingId },
    hostingStatus: {
      renewalStatus: { isExpired },
      isActive,
    },
  } = useMFEContext();

  const { data } = useHostingSites(hostingId);

  const sites = data?.data?.rows;

  const {
    isLoading: sshLoading,
    data: sshData,
    isError: isSshError,
  } = useHostingSshInfo(hostingId);

  const sshKey = sshData?.data?.credential;

  return (
    <InfoListItem
      key={ssh?.id}
      title={ssh?.label}
      tooltipText={ssh?.tooltipText}
      inlineCTA={
        <Button
          component="button"
          variant="text"
          disabled={!sites?.[0]}
          href={`/sites/${sites?.[0]?.id}/advanced#ssh`}
          data-testid="btn-advanced"
          aria-disabled
        >
          {ssh?.inlineCta}
        </Button>
      }
    >
      {sshLoading && (
        <Stack direction="row" spacing={1}>
          <Skeleton width={185} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
      )}
      {!isSshError && !sshLoading && sshKey && (
        <Copy disable={isExpired || !isActive}>
          <Typography
            sx={{ wordBreak: 'break-all' }}
            variant="body1"
            data-testid="ssh-credential"
          >
            {sshKey}
          </Typography>
        </Copy>
      )}
    </InfoListItem>
  );
};

export default SshAccessInfo;
