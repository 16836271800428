import { Button } from '@mui/material';
import { useHostingSites } from '@newfold/huapi-js';
import { useTranslation } from 'react-i18next';
import InfoListItem from '../../../../../../components/InfoListItem';
import { useMFEContext } from '../../../../../../components/MFEProvider';

const FtpAccessInfo = () => {
  const { t } = useTranslation('site');
  const { ftp } = t('scenes.advanced', { returnObjects: true });
  const { hostingId } = useMFEContext();
  const { data } = useHostingSites(hostingId);
  const sites = data?.data?.rows;

  return (
    <InfoListItem
      title={ftp?.title}
      tooltipText={ftp?.tooltip}
      {...{
        inlineCTA: (
          <Button
            disabled={!sites?.[0]}
            component="button"
            href={`/sites/${sites?.[0]?.id}/advanced#ftp`}
          >
            {ftp?.manageBtn}
          </Button>
        ),
      }}
    />
  );
};

export default FtpAccessInfo;
